import CookieUtils from 'CommonUtils/cookies.js';
import UtilsLocalStorage from 'CommonUtils/localStorage.js';
import API from 'Services/API';
import { mapGetters } from 'vuex';
const APIService = new API('/api/vuestore');

export default {
  mounted() {
    if (!this.vendors?.RetailMedia?.hasRetailMedia) {
      return;
    }
    this.getRetailMediaProductAds();
  },
  watch: {
    isLoading: function (val) {
      if (val === false) {
        this.getRetailMediaProductAds();
      }
    },
    isLoadingFilter: function (val) {
      if (val === false) {
        this.getRetailMediaProductAds();
      }
    },
  },
  computed: {
    ...mapGetters('page', {
      category_path: 'getActualCategoryPath',
      categories: 'getCategories',
      hasAcceptedCookies: 'hasAcceptedCookies',
      vendors: 'getVendors',
    }),
    ...mapGetters('filter', {
        isLoadingFilter: 'getIsLoadingFilter',
    }),
    category_ids() {
      return [this.categories.map((category) => category.id).pop()] || [];
    },
    hasRetailMedia() {
      return this.fetchedData?.vendors?.RetailMedia?.hasRetailMedia;
    },
  },
  methods: {
    getOptions() {
      const options = {
        user: {},
        placements: this.getPlacements(),
        keywords: [],
        consent: {
          gdpr: true,
        },
        categories: this.category_ids,
      };
      //if (this.hasAcceptedCookies.ADVERTISING) options.consent.gdpr = true;
      if (this.category_path) options.keywords.push(this.category_path);
      return options;
    },
    getPlacements() {
      const rmplacements = {};
      for (const p in this.products) {
        if (this.products[p].placement) {
          for (const composition in this.products[p].placement.compositions) {
            for (const artwork in this.products[p].placement.compositions[composition].artworks) {
              for (const subartwork in this.products[p].placement.compositions[composition].artworks[artwork]) {
                const artwork_item =
                  this.products[p].placement.compositions[composition].artworks[artwork][subartwork].artwork;
                if (
                  (artwork_item.settings?.slot_desktop || artwork_item.settings?.slot_mobile) &&
                  !artwork_item.product &&
                  !artwork_item.hasSponsorship
                ) {
                  const subitem_name = artwork_item.name;
                  if (subitem_name && !(subitem_name in rmplacements)) {
                    rmplacements[subitem_name] = [];
                  }
                  if (artwork_item.settings?.slot_desktop) {
                    rmplacements[subitem_name].push(artwork_item.settings.slot_desktop);
                  }
                  if (artwork_item.settings?.slot_mobile) {
                    rmplacements[subitem_name].push(artwork_item.settings.slot_mobile);
                  }
                }
              }
            }
          }
        }
      }
      const parameters = {}
      if (this.hasAcceptedCookies.ADVERTISING) {
        parameters.segments = this.getAudiences();
      }

      return Object.keys(rmplacements)
        .map((a) => ({ placement_name: a, placement_ids: rmplacements[a], ...parameters }))
        .filter((a) => a.placement_ids.length > 0);
    },
    getAudiences() {
      const audiences = [];
      const audiencesLS = UtilsLocalStorage.getItem('kxelcorteinglessa_segs');
      if (audiencesLS) {
        audiencesLS.split(',').forEach((audience) => {
          audiences.push(audience);
        });
      }
      return audiences;
    },
    getUser() {
      return CookieUtils.getCookieByName('AMCV')?.split('|').pop() || 'not-user-id';
    },
    getRetailMediaProductAds() {
      const options = this.getOptions();
      const key = this.getUser();

      options.user = {
        key
      };
      if (!options.placements.length) {
        return;
      }
      APIService.getRetailMediaProductAds(options).then((response) => {
        const isOK = response && response.success && Object.values(response.decisions).length;
        if (!isOK) {
          console.warn('[RetailMediaProduct] Service error on retail media product.');
          const products = this.products.filter((a)=>options.placements.map((b)=>b.placement_name).includes(a.placement?.name));
          for (const p in products) {
            if (products[p].placement) {
                for (const c in products[p].placement.compositions) {
                  for (const artwork in products[p].placement.compositions[c].artworks) {
                    for (const subartwork in products[p].placement.compositions[c].artworks[artwork]) {
                        products[p].placement.compositions[c].artworks[artwork][subartwork].artwork = {
                            ...products[p].placement.compositions[c].artworks[artwork][subartwork].artwork,
                            hasSponsorship: false
                        };
                    }
                  }
                }
              }
          }
          return;
        }
        console.info('[RetailMediaProduct] Service success on retail display product.');
        for (const p in this.products) {
          if (this.products[p].placement) {
            for (const c in this.products[p].placement.compositions) {
              for (const artwork in this.products[p].placement.compositions[c].artworks) {
                for (const subartwork in this.products[p].placement.compositions[c].artworks[artwork]) {
                  const placement_name =
                    this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork.name;
                  if (placement_name in response.data && response.decisions[placement_name]?.[0]) {
                    const product = response.data[placement_name][0];
                    const decision = response.decisions[placement_name][0];
                    this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork = {
                      ...this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork,
                      product: product,
                      decision,
                      hasSponsorship: true
                    };
                  } else if(!this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork.product){
                    this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork = {
                        ...this.products[p].placement.compositions[c].artworks[artwork][subartwork].artwork,
                        hasSponsorship: false
                      };
                  }
                }
              }
            }
          }
        }
      });
    },
  },
};
