import { mapActions } from 'vuex';

const layoutLogic = {
  methods: {
    ...mapActions('layout', ['dispatchUserScroll', 'dispatchVMScroll']),
    scrollVMTo(x) {
      this.dispatchVMScroll();
      window.scrollTo({ top: x, behavior: 'smooth' });
    }
  },
  mounted() {
    if (typeof document === 'undefined') return;

    // Reset flag on back/forward
    this.dispatchVMScroll();

    // Keyboard scroll
    document.onkeydown = (e) => {
      const key = e.which || e.keyCode || 0;
      const scrollKeys = [33, 34, 32, 38, 40]; // page up - page dn - spacebar - up - down
      const scrollCombinedKeys = [36, 35]; // ctrl + home/end

      if (scrollKeys.includes(key) || (scrollCombinedKeys.includes(e.ctrlKey) && scrollCombinedKeys.includes(key))) {
        this.dispatchUserScroll();
      }
    };

    // Browser scroll
    document.onscroll = this.dispatchUserScroll;

    // detect user scroll through mouse
    // Mozilla/Webkit
    document.addEventListener('DOMMouseScroll', this.dispatchUserScroll, false);
    document.addEventListener('touchmove', this.dispatchUserScroll, false);

    // for IE/OPERA etc
    document.onmousewheel = this.dispatchUserScroll;

    // for all
    document.onwheel = this.dispatchUserScroll;

    // To reset flag when named anchors are clicked
    document.querySelectorAll("a[href*='#']:not([href='#'])").forEach((el) => (el.onclick = this.dispatchVMScroll));
  },
};

export default layoutLogic;
